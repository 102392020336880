@import './variables';

* {
  box-sizing: border-box;
}

body {
  background: #F9F9F9;
}

.wave {
  background: url('/assets/images/wave.svg') no-repeat bottom;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: $wave-height;

  @media screen and (min-width: $breakpoint-small + 1) and (max-width: $breakpoint-medium-max) {
    height: $tablet-wave-height;
  }

  @media screen and (max-width: $breakpoint-small) {
    height: $phone-wave-height;
  }
}

.page {
  min-width: 100%;
  position: relative;
}

.stage {
  max-width: 1088px;
  margin: 0 auto;
  padding: 0 ($gutter * 2) ($gutter * 3) ($gutter * 2);
}

.columns {
  display: flex;
  flex-direction: row;

  .column {
    flex: 1;
    margin-right: $gutter;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;

    .column {
      margin-right: 0;
      margin-bottom: $gutter;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.small-only {
  display: none;
}

@media screen and (max-width: $breakpoint-small)  {
  .small-only {
    display: initial;
  }

  .hidden-on-small {
    display: none;
  }
}