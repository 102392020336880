/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 27, 2020 */
@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-bold-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-bolditalic-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-italic-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-light-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-light-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-lightitalic-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-lightitalic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-medium-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-mediumitalic-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-mediumitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-semibold-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../fonts/euclidcirculara-semibolditalic-webfont.woff2') format('woff2'),
       url('../fonts/euclidcirculara-semibolditalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}