// Layout
$wave-height: 766px;
$tablet-wave-height: $wave-height * 0.75;
$phone-wave-height: $wave-height * 1.25;
$header-height: 124px;

// Spacing
$gutter: 20px;

// Colors
$darkblue: #262262;
$text: #333333;
$bluegreen: #00A4E0;
$base-em-color: $bluegreen;

// Fonts
$global-font-family: 'Euclid Circular A', Arial, Helvetica, sans-serif;

// Breakpoints
$breakpoint-small: 599px;
$breakpoint-medium-max: 1279px;
