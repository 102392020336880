@use "sass:math";

.inline-cta {
  display: inline-block;
  color: white !important;
  text-transform: uppercase;
  text-align: center;
  background: $bluegreen; //transparent linear-gradient(255deg, #76B6AB 0%, #55B1BA 31%, #00A4E0 100%) 0% 0% no-repeat padding-box;
  border: 0.25px solid #BDBDBD;
  width: 242px;
  padding: math.div($gutter, 2) 0;
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;

  &:hover {
    text-decoration: none;
  }
}